<template>
    <div>
        <el-form v-model="adSetInfo" :label-width="labelWidth">
            <el-form-item label="广告组排期：">
                <div style="margin-bottom:10px">
                    <span style="font-size:12px;color:#666">开始时间：</span>
                    <el-date-picker type="datetime" v-model="adSetInfo.start_date" placeholder="选择日期时间" :disabled="!copy" value-format="yyyy-MM-dd HH:mm:ss"  @change="setDate"></el-date-picker>
                    <span class="timezoneName">{{timezoneName}}</span>
                </div>
                <div>
                    <span style="font-size:12px;color:#666">结束时间：</span>
                    <el-date-picker  type="datetime" v-model="adSetInfo.end_date" placeholder="选择日期时间" @change="setDate" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    <span class="timezoneName">{{timezoneName}}</span>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['label-width','scheduleMsg','copy'],
        data() {
            return {
                adSetInfo:JSON.parse(JSON.stringify(this.scheduleMsg))
            }
        },
        computed:{
            timezoneName(){
                return this.$store.getters.currentAccountInfo.timezoneName
            }
        },
        methods: {
            setDate(){
                this.$emit('getSchedule',this.adSetInfo)
            }
        },
        watch:{
            scheduleMsg:{
                handler(){
                    this.adSetInfo = JSON.parse(JSON.stringify(this.scheduleMsg));
                }
            }
        }

    }
</script>
<style lang="scss" scope>
    
</style>