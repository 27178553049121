<template>
    <div>
        <el-form :label-width="labelWidth" v-model="localsInfo">
            <el-form-item label="语言：" prop="sex">
                <el-select v-model="localsInfo.locals" placeholder="请选择语言" multiple clearable filterable style="width:100%" v-if="initData.targeting" @change="setLocals">
                    <el-option :label="local.name" :value="local.key" v-for="local in initData.targeting.TargetingLocale" :key="local.key"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['label-width','localsMsg'],
        data() {
            return {
                localsInfo:{
                    locals:JSON.parse(JSON.stringify(this.localsMsg))
                },
                initData:this.$store.getters.initData,
                currentSelect:this.$store.getters.currentSelect,
                baseData:this.$store.getters.baseData,
            }
        },
        computed:{

        },
        methods: {
            setLocals(v){
                this.$emit('setLocals',v)
            }
        },
        watch:{
            localsMsg:{
                handler(){
                    this.localsInfo.locals = this.localsMsg;
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss" scope>
    
</style>