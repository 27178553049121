<template>
    <div>
        <el-form v-model="ruleForm" :inline="true" :label-width="labelWidth">
            <el-form-item label="像素：" prop="customEventType">
                <div class="seletItem">
                    <el-select v-model="ruleForm.pixel_id" placeholder="请选择像素" @change="selectPixelStatic">
                        <el-option :label="item.name" :value="item.pixel_id" v-for="(item,index) in accountPixelList" :key="index"></el-option>
                        <div slot="empty" class="empty"  v-loading="loadingPix">
                            <span>
                                <i class="iconfont icon-wushuju"></i>
                                <p>暂无数据</p>
                            </span>
                        </div>
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="强制刷新像素，清除本地缓存像素数据" placement="top">
                        <el-button icon="el-icon-refresh" @click="getPixels(true)" type="text" size="mini" :disabled="loadPixel"></el-button>
                    </el-tooltip>
                    <p style="color:#999">当前像素id：{{ruleForm.pixel_id || '--'}}</p>
                </div>
                
            </el-form-item>
            <el-form-item label="转化事件" prop="customEventType">
                <el-select v-model="ruleForm.custom_event_type" placeholder="" @change="emitMsg">
                    <el-option :value="item.event.customEventType" v-for="(item,index) in pixelStaticList" :key="index" :label="item.event.title">
                        <span :class="['point',item.count>0?'':'active']"></span>
                        <span>{{item.event.title}}</span>
                        <span style="margin-left:10px" v-if="item.count">已收到{{item.count}}个活动</span>
                    </el-option>
                    <div slot="empty" class="empty"  v-loading="loadingPix">
                        <span>
                            <i class="iconfont icon-wushuju"></i>
                            <p>暂无数据</p>
                        </span>
                    </div>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import {pixelStats} from '@/api/creatAd.js'
    export default {
        props:['pixelMsg','labelWidth','accountPixelList','loadPixel'],
        data () {
            return {
                ruleForm:JSON.parse(JSON.stringify(this.pixelMsg)),
                pixelStaticList:[],
                pixelList:[],
                loadingPix:false
            }
        },
        methods: {
            // 选择像素事件
            selectPixelStatic(v){
                if(!v) return false;
                this.pixelStaticList = [];
                this.loadingPix = true;
                this.emitMsg()
                pixelStats({pixelId:v}).then(res=>{
                    this.loadingPix = false;
                    if(res.code == 0){
                        this.pixelStaticList = res.data.stats;
                    }
                })
            },
            emitMsg(){
                console.log(this.ruleForm);
                this.$emit('getPixelMsg',this.ruleForm)
            },
            getPixels(v){
                this.$emit('getPixels',v)
            }
        },
        watch: {
            pixelMsg:{
                handler(){
                    this.ruleForm = JSON.parse(JSON.stringify(this.pixelMsg));
                    this.selectPixelStatic(this.pixelMsg.pixel_id)
                },
                deep:true,
                immediate: true,
            }
        }
    }
</script>
<style lang="scss">
    
</style>