<template>
    <div>
        <el-form :label-width="labelWidth">
            <el-form-item label="国家：">
                <el-select v-model="locationForm.geo_locations" placeholder="请选择" multiple filterable clearable style="width:80%" @change="setLocations">
                    <el-option-group v-for="(group,i) in selectGeoLocations" :key="i" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.key" :label="item.name" :value="item.key" :disabled="item.disabled"></el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item label="省份：">
                <el-row >
                    <el-col :span="14">
                        <el-select v-model="locationForm.geo_locations_region" placeholder="请选择" multiple filterable clearable style="width:100%" @change="setLocations">
                            <!-- <el-option v-for="item in regionsList" :key="item.key" :label="item.name" :value="item.key"> </el-option> -->
                            <el-option-group v-for="group in regionsList" :key="group.country.key" :label="group.country.name">
                                <el-option v-for="(item,index) in group.regions" :key="index" :label="item.name" :value="item.key" :disabled="group.disabled || item.disabled"></el-option>
                            </el-option-group>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <region-group :list="locationForm.geo_locations_region" @set-regions="setRegions" savedType="GEO_REGION" :geos="locationForm.geo_locations ||[]" type="key" :account="account" :targetUserId="targetUserId"></region-group>
                    </el-col>
                </el-row>
                
            </el-form-item>
            <el-form-item label="城市：">
                <el-row >
                    <el-col :span="14">
                        <el-select v-model="locationForm.geo_locations_city" placeholder="请选择" multiple filterable clearable style="width:100%" @change="setLocations">
                            <!-- <el-option v-for="item in cityList" :key="item.key" :label="item.name" :value="item.key"> </el-option> -->
                            <el-option-group v-for="group in cityList" :key="group.country.key" :label="group.country.name">
                                <el-option v-for="(item,index) in group.cities" :key="index" :label="item.name" :value="item.key" :disabled="group.disabled"></el-option>
                            </el-option-group>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <region-group :list="locationForm.geo_locations_city" @set-regions="setCity"  savedType="GEO_CITY" :geos="locationForm.geo_locations||[]" type="key" :account="account" :targetUserId="targetUserId"></region-group>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="" prop="region">
                <el-dropdown @command="handlePeopleGroup"> 
                    <span class="el-dropdown-link">{{peopleName}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="people.id" v-for="(people) in peopleGroup"
                                            :key="people.id">{{people.name}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button @click="excludeShow = !excludeShow" size="mini" style="margin-left:50px" type="text">排除地区
                </el-button>
            </el-form-item>
            <el-form-item label="排除地区：" prop="region" v-if="excludeShow">
                <el-select v-model="locationForm.excluded_geo_locations" placeholder="请选择" multiple
                            filterable clearable style="width:80%" @change="setLocations">
                    <el-option-group
                            v-for="group in selectExcludeGeoLocations"
                            :key="group.label"
                            :label="group.label">
                        <el-option
                                v-for="item in group.options"
                                :key="'ex_'+item.key"
                                :label="item.name"
                                :value="item.key" :disabled="item.exdisabled">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item label="排除省份：" prop="region" v-if="excludeShow">
                <el-row >
                    <el-col :span="16">
                        <el-select v-model="locationForm.excluded_geo_locations_regions" placeholder="请选择" multiple filterable clearable style="width:100%"  value-key="key"  @change="setLocations">
                            <el-option-group
                                    v-for="group in excludeRegionsList"
                                    :key="group.country.name"
                                    :label="group.country.name">
                                <el-option
                                        v-for="item in group.regions"
                                        :key="'ex_'+item.key"
                                        :label="item.name"
                                        :value="item.key" :disabled="item.disabled || group.disabled">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <region-group :list="locationForm.excluded_geo_locations_regions" @set-regions="setExcludeRegion"  savedType="EXCLUDED_GEO_REGION" :geos="[]" type="key" :account="account" :targetUserId="targetUserId"></region-group>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="排除城市：" prop="region" v-if="excludeShow">
                <el-select v-model="locationForm.excluded_geo_locations_cities" placeholder="请选择" multiple
                            filterable clearable style="width:100%" @change="selectExCities">
                    <el-option-group
                            v-for="group in excludedCityList"
                            :key="group.country.key"
                            :label="group.country.name">
                        <el-option
                                v-for="item in group.cities"
                                :key="'ex_'+item.key"
                                :label="item.name"
                                :value="item.key" :disabled="item.disabled || group.disabled">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item label="排除下级城市：" prop="region" v-if="excludeShow">
                <el-select v-model="locationForm.excluded_geo_locations_subcities" placeholder="请选择" multiple
                            filterable clearable style="width:100%" @change="selectExSubCities">
                    <el-option-group
                            v-for="group in excludedSubCityList"
                            :key="group.country.key"
                            :label="group.country.name">
                        <el-option
                                v-for="item in group.cities"
                                :key="'ex_'+item.key"
                                :label="item.name"
                                :value="item.key" :disabled="item.disabled || group.disabled">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item label="排除城镇：" prop="region" v-if="excludeShow">
                <el-row>
                    <el-col :span="16">
                        <el-select v-model="locationForm.excluded_geo_locations_places" placeholder="请选择" multiple
                            filterable clearable style="width:100%" @change="selectExPlaces">
                    <el-option-group
                            v-for="group in excludedPlacesList"
                            :key="group.country.key"
                            :label="group.country.name">
                        <el-option
                                v-for="item in group.places"
                                :key="'ex_'+item.key"
                                :label="item.name"
                                :value="item.key" :disabled="item.disabled || group.disabled">
                        </el-option>
                    </el-option-group>
                </el-select>
                    </el-col>
                    <el-col :span="8">
                        <el-input placeholder="请输入0-80的整数" v-model="locationForm.excluded_geo_locations_place_radius" type="number" :min="1"  :max="80" @change="changeRadius">
                            <template slot="append">公里</template>
                        </el-input>
                    </el-col>
                </el-row>
                
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import initData from '../initData'
    import regionGroup from '../../../createAd/components/regionGroup'
    export default {
        props:{
            'label-width':{
                type:String,
                default:'140px'
            },
            'locationMsg':{
                type:Object,
                default:(()=>{})
            },
            account:{
                type:String,
                default:''
            },
            targetUserId:{
                type:String,
                default:''
            }
        },
        mixins:[initData],
        data() {
            return {
                countryList:[{ label: '', options: this.$store.getters.initData.targeting.TargetingCountry }],
                locationForm: JSON.parse(JSON.stringify(this.locationMsg)),
                excludeShow:false,
                peopleGroup: [{name: '该地区内的所有用户', id: 'all'}, {name: '居住在该地区的用户', id: 'home'}, {  name: '最近位于该地区的用户', id: 'recent' }, {name: '到该地区旅游的用户', id: 'travel_in'}],
                init:this.$store.getters.initData
            }
        },
        components:{
            regionGroup
        },
        mounted() {
        },
        created() {
            
        },
        computed:{
            peopleName(){
                return this.peopleGroup.filter(v=>v.id == this.locationForm.location_types).length?this.peopleGroup.filter(v=>v.id == this.locationForm.location_types)[0].name:''
            },
            selectGeoLocations() {
                let countryList = this.countryList;
                let geoV = this.locationForm.excluded_geo_locations || [];
                let regionsList =  this.locationForm.geo_locations_region || [];
                let cityList =  this.locationForm.geo_locations_city || [];
                for (var i = 0; i < countryList.length; i++) {
                    // if(!countryList[i].label){
                        countryList[i].options.map(o => o.disabled = (geoV.includes(o.key) || regionsList.some(v=>v.country_code == o.key) || cityList.some(m=>m.country_code == o.key) ))
                    // }
                }
                return countryList;
            },
            selectExcludeGeoLocations() {
                let countryList = this.countryList;
                let geoV = this.locationForm.geo_locations || [];
                for (var i = 0; i < countryList.length; i++) {
                    countryList[i].options.map(o => o.exdisabled = geoV.includes(o.key))
                }
                return countryList;
            },
            // regionsList(){
            //     let regions = this.init.targeting?this.init.targeting.TargetingGeoRegions : [];
            //     let geoV = this.locationForm.geo_locations || [];
            //     regions.map(v=>v.disabled = geoV.includes(v.country.key))
            //     return regions
            // },
            regionsList(){
                let regions = this.init.targeting?this.init.targeting.TargetingGeoRegions : [];
                let geoV = this.locationForm.geo_locations || [];
                let excluded_geo_region = this.locationForm.excluded_geo_locations_regions || [];
                let excludedGeo = this.locationForm.excluded_geo_locations || [];
                regions.map(v=>v.disabled = excludedGeo.includes(v.country.key))
                regions.map(v=>{
                    v.regions.map(o=>o.disabled = excluded_geo_region.includes(o.key ))
                })
                // if(regions.filter(v=>geoV.includes(v.country_code)).length == 0){
                //     this.locationForm.geo_locations_region = []
                // }
                return regions
                // return regions.filter(v=>geoV.includes(v.
            },
            excludeRegionsList(){
                let regions = this.init.targeting?this.init.targeting.TargetingGeoRegions : [];
                let geoV = this.locationForm.geo_locations_region || [];
                let excludedGeo = this.locationForm.excluded_geo_locations || [];
                regions.map(v=>v.disabled = excludedGeo.includes(v.country.key))
                regions.map(v=>{
                    v.regions.map(o=>o.disabled = geoV.includes(o.key))
                })
                // if(regions.filter(v=>geoV.includes(v.country_code)).length == 0){
                //     this.locationForm.geo_locations_region = []
                // }
                return regions
                // return regions.filter(v=>geoV.includes(v.country_code))
            },
            cityList(){
                let citys = this.init.targeting?this.init.targeting.TargetingGeoCitys : [];
                let geoV = this.locationForm.geo_locations || [];
                citys.map(v=>v.disabled = geoV.includes(v.country.key))
                return citys
            },
            excludedCityList(){
                let citys = this.init.targeting?this.init.targeting.TargetingGeoCitys : [];
                let excludedGeo = this.locationForm.excluded_geo_locations || [];
                let excludedRegions = this.locationForm.excluded_geo_locations_regions || [];
                citys.map(v=>v.disabled = (excludedGeo.includes(v.country.key)))
                citys.map(v=>{
                    v.cities.map(k=>k.disabled = excludedRegions.map(v=>v.key).includes(k.region_id))
                })
                return citys
            },
            excludedSubCityList(){
                let citys = this.init.targeting?this.init.targeting.TargetingGeoSubCities : [];
                let excludedGeo = this.locationForm.excluded_geo_locations || [];
                let excludedRegions = this.locationForm.excluded_geo_locations_regions || [];
                citys.map(v=>v.disabled = (excludedGeo.includes(v.country.key)))
                citys.map(v=>{
                    v.cities.map(k=>k.disabled = excludedRegions.map(v=>v.key).includes(k.region_id))
                })
                return citys
            },
            excludedPlacesList(){
                let places = this.init.targeting?this.init.targeting.TargetingGeoPlaces : [];
                let excludedGeo = this.locationForm.excluded_geo_locations || [];
                let excludedRegions = this.locationForm.excluded_geo_locations_regions || [];
                let excludedCities = this.locationForm.excluded_geo_locations_cities || [];
                places.map(v=>v.disabled = (excludedGeo.includes(v.country.key)))
                places.map(v=>{
                    v.places.map(k=>k.disabled = (excludedRegions.map(v=>v.key).includes(k.region_id)) || (excludedCities.map(v=>v.key).includes(k.primary_city_id)))
                })
                return places
            },
        },
        watch:{
            locationMsg:{
                handler(){
                    if(this.locationMsg){
                        this.locationForm = JSON.parse(JSON.stringify(this.locationMsg));
                        this.handlePeopleGroup(this.locationForm.location_types || 'all');
                        if((this.locationForm.excluded_geo_locations && this.locationForm.excluded_geo_locations.length) || (this.locationForm.excluded_geo_locations_regions && this.locationForm.excluded_geo_locations_regions.length)||(this.locationForm.excluded_geo_locations_cities && this.locationForm.excluded_geo_locations_cities.length) ||(this.locationForm.excluded_geo_locations_subcities && this.locationForm.excluded_geo_locations_subcities.length)||(this.locationForm.excluded_geo_locations_places && this.locationForm.excluded_geo_locations_places.length)){
                            this.excludeShow = true;
                        }
                    }
                },
                deep:true
            },
        },
        methods: {
            // 设置省份
            setRegions(v){
                this.locationForm.geo_locations_region = JSON.parse(v).map(v=>v.key);
                this.setLocations();
            },
            setCity(v){
                this.locationForm.geo_locations_city = JSON.parse(v).map(v=>v.key);
                this.setLocations();
            },
            setExcludeRegion(v){
                this.locationForm.excluded_geo_locations_regions = JSON.parse(v).map(v=>v.key);
                this.setLocations();
            },
            selectExCities(v){
                this.locationForm.excluded_geo_locations_cities = [...v];
                this.setLocations();
            },
            selectExSubCities(v){
                this.locationForm.excluded_geo_locations_subcities = [...v];
                this.setLocations();
            },
            selectExPlaces(v){
                this.locationForm.excluded_geo_locations_places = [...v];
                this.setLocations();
            },
            changeRadius(v){
                this.locationForm.excluded_geo_locations_place_radius = v/1;
                this.setLocations();
            },
            // 选择地区的受众人群
            handlePeopleGroup(peoples) {
                let people = this.peopleGroup.filter(v => v.id == peoples);
                this.locationForm.peopleGroup = people&&people.length?people[0].name:'';
                this.locationForm.location_types = peoples;
                this.setLocations()
            },
            setLocations(){
                this.$emit('editBaseList',JSON.parse(JSON.stringify(this.locationForm)))
            }
        },
    }
</script>
<style lang="scss" scope>
    
</style>