<template>
    <div>
        <el-dialog title="排期" :visible="scheduleMixShow" width="800px" @close="close" @opened="openInit" append-to-body>
            <div class="localsList">
                <el-row :gutter="10">
                    <el-col :span="4" style="line-height:40px">
                        <span  class="mixName">修改选择项</span>
                    </el-col>
                    <el-col :span="16">
                        <el-row>
                            <el-col :span="11">
                                <el-date-picker type="datetime" v-model="start_date" placeholder="选择日期时间" :disabled="!copy" value-format="yyyy-MM-dd HH:mm:ss" @change="selectStart"></el-date-picker>
                            </el-col>
                            <el-col :span="2">-</el-col>
                            <el-col :span="11">
                                <el-date-picker type="datetime" v-model="end_date" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" @change="selectEnd"></el-date-picker>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-table :data="list" style="width: 100%" @selection-change="handleSelectionChange" ref="scheduleTable">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="name" label="名称" width="180"> </el-table-column>
                    <el-table-column prop="date" label="排期">
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="11">
                                    <el-date-picker type="datetime" v-model="scope.row.start_date" placeholder="选择日期时间" :disabled="!copy" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                </el-col>
                                <el-col :span="2">-</el-col>
                                <el-col :span="11">
                                    <el-date-picker type="datetime" v-model="scope.row.end_date" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-row :gutter="10" v-for="item in list" :key="item.id">
                    <el-col :span="2" style="line-height:40px">
                        <span  class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="6" style="line-height:40px">
                        <span  class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="16">
                        
                    </el-col>
                </el-row> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        props:['scheduleMixShow','scheduleMsgList','copy'],
        data() {
            return {
                list:JSON.parse(JSON.stringify(this.scheduleMsgList)),
                editSelect:[],
                start_date:'',
                end_date:''
            }
        },
        methods: {
            handleSelectionChange(v){
                this.editSelect = v;
            },
            selectStart(val){
                this.editSelect.map(v=>{
                    this.list.map((item,index)=>{
                        if(v.id==item.id){
                            item.start_date = val
                        }
                        this.$set(this.list,index,item)
                    })
                })
                // this.list = JSON.parse(JSON.stringify(this.list))
                console.log(this.list);
            },
            selectEnd(val){
                this.editSelect.map(v=>{
                    this.list.map((item,index)=>{
                        if(v.id==item.id){
                            item.end_date = val
                        }
                        this.$set(this.list,index,item)
                    })
                })
                // this.list = JSON.parse(JSON.stringify(this.list))
            },
            close(){
                this.$emit("update:scheduleMixShow",false)
            },
            openInit(){
                this.list = JSON.parse(JSON.stringify(this.scheduleMsgList));
                this.$refs.scheduleTable.toggleAllSelection();
            },
            sure(){
                this.$emit('setMixMsg','schedule',this.list);
                this.close()
            }
        },
        watch:{
            scheduleMsgList:{
                handler(){
                    
                }
            }
        }
    }
</script>
<style lang="scss" scope>
    .localsList{
        .el-row{
            margin-bottom: 10px;
        }
    }
</style>